<div class="main-block flex  justify-center relative">
  <div class="bg-white rounded-lg p-3"  style="width:43%;">
    <div class="text-center">
      <h2 class="tv-title">Top Revenue Dispatcher</h2>
    </div>
    
    
    <div class="top_list mt-5">
        <div class="top_list_header flex items-center">
            <div class="name header-block pl-3">
              Name               
              <button
                (click)="onSortDispatcherTrips('name', sortable.sort == 'incr' ? 'decr' : 'incr')"
                class="filter-sort-btn"
              >
                <i class="fa-solid text-gray-500"
                [ngClass]="{
                    'fa-chevron-down': sortable.sort == 'incr' || sortable.field !== 'name',
                    'fa-chevron-up': sortable.sort == 'decr' && sortable.field == 'name'
                    }"
                ></i>
              </button>
            </div>
            <div class="orders header-block">
                Orders

                <button
                  (click)="onSortDispatcherTrips('ordersCount', sortable.sort == 'incr' ? 'decr' : 'incr')"
                  class="filter-sort-btn"
                >
                  <i class="fa-solid text-gray-500"
                  [ngClass]="{
                      'fa-chevron-down': sortable.sort == 'incr' || sortable.field !== 'ordersCount',
                      'fa-chevron-up': sortable.sort == 'decr' && sortable.field == 'ordersCount'
                      }"
                  ></i>
                </button>
            </div>
            <div class="vehicles header-block">
                Vehicle
                <button
                  (click)="onSortDispatcherTrips('vehiclesCount', sortable.sort == 'incr' ? 'decr' : 'incr')"
                  class="filter-sort-btn"
                >
                  <i class="fa-solid text-gray-500"
                  [ngClass]="{
                      'fa-chevron-down': sortable.sort == 'incr' || sortable.field !== 'vehiclesCount',
                      'fa-chevron-up': sortable.sort == 'decr' && sortable.field == 'vehiclesCount'
                      }"
                  ></i>
                </button>
            </div>
            <div class="avg_booking header-block">
                Avg Booking
                <button
                  (click)="onSortDispatcherTrips('avgBooking', sortable.sort == 'incr' ? 'decr' : 'incr')"
                  class="filter-sort-btn"
                >
                  <i class="fa-solid text-gray-500"
                  [ngClass]="{
                      'fa-chevron-down': sortable.sort == 'incr' || sortable.field !== 'avgBooking',
                      'fa-chevron-up': sortable.sort == 'decr' && sortable.field == 'avgBooking'
                      }"
                  ></i>
                </button>
            </div>
            <div class="total_amount header-block">
                Total Amount
                <button
                  (click)="onSortDispatcherTrips('totalAmount', sortable.sort == 'incr' ? 'decr' : 'incr')"
                  class="filter-sort-btn"
                >
                  <i class="fa-solid text-gray-500"
                  [ngClass]="{
                      'fa-chevron-down': sortable.sort == 'incr' || sortable.field !== 'totalAmount',
                      'fa-chevron-up': sortable.sort == 'decr' && sortable.field == 'totalAmount'
                      }"
                  ></i>
                </button>
            </div>
        </div>
        <div class="top_list_data mt-3">
          @if(data?.dispatcherTrips){
            @for(row of data?.dispatcherTrips; track $index){
              <div class="top_list_item" [ngClass]="{'font-bold': $first}">
                <div class="name data-block pl-3">{{$index + 1}}. {{row?.name}}</div>
                <div class="orders data-block">{{row.ordersCount}}</div>
                <div class="vehicles data-block">{{row.vehiclesCount}}</div>
                <div class="avg_booking data-block">
                  <div class="flex items-start">
                    <span class="font-bold flex items-center px-3 rounded-lg bg-orange-100" style="color: #8A2C0D;">~ $ {{row.avgBooking | myCurrency}}</span>
                  </div>
                </div>
                <div class="total_amount data-block">
                  <div class="flex items-center gap-2">
                    <span>$ {{row.totalAmount | myCurrency}}</span>

                    <button (click)="excludeDispatcher(row._id)" class="remove-btn">
                      <i class="fa-solid fa-xmark"></i>
                    </button>
                  </div>
                    
                </div>
              </div>  
            }  
          }@else {
            <app-preloader [type]="'CONTENT_LIST'" [count]="15"></app-preloader>
          }
            
        </div>  
    </div>
  </div>

  <div class="bg-white rounded-lg p-3" style="width:57%;">
    <div class="text-center flex items-center justify-center">
      <h2 class="tv-title ml-auto">Logistics Performance</h2>

      <button 
        [popper]="filterIsOpen"
        [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.LEFT" [popperTrigger]="NgxPopperjsTriggers.click" [popperHideOnClickOutside]="true"
        class="ml-auto px-2 py-2 bg-blue-700 text-white rounded-lg flex gap-1 items-center justify-center filter-icon">
        <span>{{filters?.excludeIds?.length || ''}}</span>
        <i class="fa-solid fa-filter "></i>
      </button>

      <popper-content #filterIsOpen>
        <div class="bg-white rounded-lg p-3 border w-60">
          <app-multi-select (onSave)="selectUser($event)" [idInfo]="'dispatcher-filter'" [data]="users" [value]="filters?.excludeIds"  [keyName]="{key:'_id', name: 'name'}"  [visibleCount]="1" [placeholder]="'Exclude Users'" [label]="'Exclude Users'"></app-multi-select>
        </div>
      </popper-content>
    </div>


    <div class="top_list mt-5">
      <div class="top_list_header flex bg-gray-50 ">
        <div class="header-block header-block__logistics" style="width:14%;">
            Dispatcher
        </div>
        <div class="header-block header-block__logistics" style="width:8%;">
            Early Pickup
        </div>
        <div class="header-block header-block__logistics" style="width:13%;">
            On Time Pickup
        </div>
        <div class="header-block header-block__logistics" style="width:8%;">
            Late Pickup
        </div>
        <div class="header-block header-block__logistics" style="width:13%;">
            No date Pickup
        </div>
        <div class="header-block header-block__logistics" style="width:9%;">
            Early Delivery
        </div>
        <div class="header-block header-block__logistics" style="width:11%;">
            On Time Delivery
        </div>
        <div class="header-block header-block__logistics" style="width:8%;">
            Late Delivery
        </div>
        <div class="header-block header-block__logistics" style="width:11%;">
            No date Delivery
        </div>
        <div class="header-block header-block__logistics" style="width:5%;">
            Total
        </div>
    </div>
    
    <div class="top_list_data ">
      @if(data?.logisticsPerformance){
        @for(row of data?.logisticsPerformance; track $index){
          <div class="top_list_item p-0" [ngClass]="{'font-bold': $first}">
            <div class="data-block data-block__logistics bg-gray-50" style="width:14%;">
              {{$index+1}}. {{row?.dispatcherName}}
            </div>
            <div class="data-block data-block__logistics" style="width:8%;">
              @if(row?.stats?.origin?.early) {
                <span class="rounded-lg px-3 bg-gray-200 text-gray-900">
                  {{row?.stats?.origin?.early}} - {{(row?.stats?.origin?.early / row?.stats?.total * 100).toFixed()}}%
                </span>
              }  
            </div>
            <div class="data-block data-block__logistics" style="width:13%;">
              @if(row?.stats?.origin?.onTime) {
                <span class="rounded-lg px-3 bg-green-100 text-green-800">
                  {{row?.stats?.origin?.onTime}} - {{(row?.stats?.origin?.onTime / row?.stats?.total * 100).toFixed()}}%
                </span>
              }
            </div>
            <div class="data-block data-block__logistics" style="width:8%;">
              @if (row?.stats?.origin?.late) {
                <span class="rounded-lg px-3 bg-red-100 text-red-800">
                  {{row?.stats?.origin?.late}} - {{(row?.stats?.origin?.late / row?.stats?.total * 100).toFixed()}}%
                </span>
              }
            </div>
            <div class="data-block data-block__logistics" style="width:13%;">
              @if (row?.stats?.origin?.noDate) {
                <span class="text-gray-500">
                  {{row?.stats?.origin?.noDate}} - {{(row?.stats?.origin?.noDate / row?.stats?.total * 100).toFixed(1)}}%
                </span>
              }@else {
                -
              }
            </div>
            <div class="data-block data-block__logistics" style="width:9%;">
              @if(row?.stats?.destination?.early) {
                <span class="rounded-lg px-3 bg-gray-200 text-gray-900">
                  {{row?.stats?.destination?.early}} - {{(row?.stats?.destination?.early / row?.stats?.total * 100).toFixed()}}%
                </span>
              } 
            </div>
            <div class="data-block data-block__logistics" style="width:11%;">
              @if (row?.stats?.destination?.onTime) {
                <span class="rounded-lg px-3 bg-green-100 text-green-800">
                  {{row?.stats?.destination?.onTime}} - {{(row?.stats?.destination?.onTime / row?.stats?.total * 100).toFixed()}}%
                </span>
              }
            </div>
            <div class="data-block data-block__logistics" style="width:8%;">
              @if (row?.stats?.destination?.late) {
                <span class="rounded-lg px-3 bg-red-100 text-red-800">
                  {{row?.stats?.destination?.late}} - {{(row?.stats?.destination?.late / row?.stats?.total * 100).toFixed()}}%
                </span>
              }
            </div>
            <div class="data-block data-block__logistics" style="width:11%;">
              @if (row?.stats?.destination?.noDate) {
                <span class="text-gray-500">
                  {{row?.stats?.destination?.noDate}} - {{(row?.stats?.destination?.noDate / row?.stats?.total * 100).toFixed(1)}}%
                </span>
              }@else {
                -
              }
            </div>
            <div class="data-block data-block__logistics" style="width:5%;">
              <div class="flex items-center gap-1">
                {{row?.stats?.total}}

                <button (click)="excludeDispatcher(row.dispatcherId)" class="remove-btn">
                  <i class="fa-solid fa-xmark"></i>
                </button>
              </div>
            </div>
          </div>
        }
      }@else {
        <app-preloader [type]="'CONTENT_LIST'" [count]="15"></app-preloader>
      }
    </div>
  </div>

  </div>
</div>


