import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardsService {
  private path = '/dashboards';

  constructor(private apiService: ApiService) {}

  get(filter) {
    return this.apiService.get(this.path, filter);
  }

}