<div class="dashboard-filter-wrapper">
    <div class="dashboard-filter">
        <div>
            <app-select
                [label]="filters?.dataRange ? 'Date Range' : 'Select Date Range'"
                [idInfo]="'filter-range'"
                [keyName]="{key:'key', name: 'name'}"
                [value]="filters?.dataRange"
                [list]="rangeTypes"
                [options]="{noFirstOption: true}"
                (onSave)="selectDateRange($event)"
            ></app-select>
        </div>
        <div>
            <app-date
                [label]="'From'"
                [defaultDate]="filters?.startDate"
                [startKey]="'startDate'"
                [parent]="filters"
                [options]="{ timezone: false, fullFormat: true, notDelete: true }"
                (onSave)="setFilter.emit({'startDate': $event.startDate})"
            ></app-date>
        </div>
        <div>
            <app-date
                [label]="'To'"
                [defaultDate]="filters?.endDate"
                [startKey]="'endDate'"
                [parent]="filters"
                [options]="{ timezone: false, fullFormat: true, notDelete: true }"
                (onSave)="setFilter.emit({'endDate': $event.endDate})"
            ></app-date>
        </div>
        <div>
            <app-search
                *ngIf="!filters?.equipmentId"
                [idInfo]="'equipment'"
                [label]="'Unit'"
                [resource]="'EQUIPMENT'"
                [options]="{focus: true}"
                [method]="'search'"
                [filter]="{carrierId: currentUser?.company?._id, type: 'TRUCK'}"
                (onChange)="setFilter.emit({equipmentId: $event._id, equipmentName: $event.name})"
            ></app-search>
            <app-input
                *ngIf="filters?.equipmentId"
                [label]="'Unit'"
                [value]="filters?.equipmentName"
                [display]="{ remove: true }"
                [idInfo]="'filter-equipment'"
                [isReadOnly]="true"
                (onRemove)="setFilter.emit({equipmentId: null, equipmentName: null})"
            ></app-input>
        </div>
        <div>
            <app-search
                *ngIf="!filters?.driverId"
                [label]="'Driver'"
                [resource]="'CONTACT'"
                [idInfo]="'filter-driver'"
                [options]="{focus: true}"
                [method]="'search'"
                [filter]="{accountId: currentUser?.company._id, type: 'DRIVER', limit: 25, sort: 'quoteCount', dir: 'desc', isAccount: false}"
                (onChange)="setFilter.emit({driverId: $event?._id, driverName: $event?.name})"
            ></app-search>
            <app-input
                *ngIf="filters?.driverId"
                [label]="'Driver'"
                [value]="filters?.driverName"
                [display]="{ remove: true }"
                [idInfo]="'filter-driver'"
                [isReadOnly]="true"
                (onRemove)="setFilter.emit({driverId: null, driverName: null})"
            ></app-input>
        </div>
    </div>
    <button class="reset_btn" (click)="resetFilter()" [disabled]="loading">Reset</button>
</div>
