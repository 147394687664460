import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SidebarService } from '@app/core/services/sidebar.service'
import { Store } from '@ngrx/store';
import moment from 'moment'
import { NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs'
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import * as fromStore from '@app/shared/store'
import { J } from '@fullcalendar/core/internal-common'
import { url } from 'inspector'
import { selectAllUsers } from '@app/sidebar/store'

@Component({
  selector: 'app-dashboard-tv',
  templateUrl: './dashboard-tv.component.html',
  styleUrls: ['./dashboard-tv.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardTvComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>()

  placements = NgxPopperjsPlacements;
  triggers = NgxPopperjsTriggers;

  data

  defaultDate

  interval

  sortable = {
    sort: 'incr',
    field: 'name'
  }

  filters

  NgxPopperjsPlacements = NgxPopperjsPlacements
  NgxPopperjsTriggers = NgxPopperjsTriggers

  users


  constructor(private sidebarService: SidebarService, private store: Store<fromStore.State>, private cdr: ChangeDetectorRef, private router: Router, private route: ActivatedRoute) {
    let urlParams = { ...this.route.snapshot.queryParams }; // Create a shallow copy
    urlParams.excludeIds = urlParams?.excludeIds?.split(',')?.filter(id => id)
    this.store.dispatch(new fromStore.GetDashboardsFilters(urlParams))
  }

  ngOnInit() {
    this.defaultDate = {
      startDate: moment.utc().startOf('month').toISOString(),
      endDate: moment.utc().endOf('month').toISOString(),
      dataRange: 'thisMonth'
    }

    this.store.select(selectAllUsers).pipe(
      takeUntil(this.destroyed$),
      tap(users => {
        this.users = users.filter(u=>u?.name)
      })
    ).subscribe()

    this.store.select(fromStore.selectAllFiltersDashboards)
      .pipe(
        takeUntil(this.destroyed$),
        tap(filter => {
          filter = {...filter, ...this.defaultDate}
          this.filters = filter
          this.store.dispatch(new fromStore.GetDashboardsTv(filter))
          this.cdr.detectChanges()
        })
    )
    .subscribe()

    this.store.select(fromStore.selectDashboards).pipe(
      takeUntil(this.destroyed$),
    ).subscribe(
      (data) => {
        this.data = data
        if(this.data.logisticsPerformance?.length) {
          this.data.logisticsPerformance = this.data.logisticsPerformance.map((item, index) => {
            const pickupOnTime = (item?.stats?.origin?.onTime / item?.stats?.total * 100).toFixed()
            const deliveryOnTime = (item?.stats?.destination?.onTime / item?.stats?.total * 100).toFixed()
            item.performance = (parseFloat(deliveryOnTime) + parseFloat(pickupOnTime)) / 2;
            return item
          })?.sort((a, b) => b.stats?.total - a.stats?.total).sort((a, b) => b.performance - a.performance)
        }
        if(this.data.dispatcherTrips?.length) {
          this.data.dispatcherTrips.sort((a, b) => b.totalAmount - a.totalAmount)
        }
        this.cdr.detectChanges()
      }
    )

    this.interval = setInterval(() => {
      this.store.dispatch(new fromStore.GetDashboardsTv(this.filters))
    }, 18000 * 1000) // 30 minutes

    this.sidebarService.setShowNavigation(false)
    this.sidebarService.setShowSidebar(false)
  }


  onSortDispatcherTrips( field, sort) {

    this.sortable = {
        sort,
        field
    }
    if (field == 'name') {
        if (this.sortable.sort == 'incr') {
            this.data.dispatcherTrips?.sort((a, b) => a.name.localeCompare(b.name))
        } else {
            this.data?.dispatcherTrips?.sort((a, b) => b.name.localeCompare(a.name))
        }
    } else {
        if (this.sortable.sort == 'incr') {
            this.data?.dispatcherTrips?.sort((a, b) => a[field] - b[field])
        } else {
            this.data?.dispatcherTrips?.sort((a, b) => b[field] - a[field])
        }
    }
  }


  selectUser(event){
    this.store.dispatch(new fromStore.GetDashboardsFilters({
      ...this.defaultDate,
      excludeIds: event
    }))
    this.router.navigate(
      [`/dashboards/tv`],
      {
        queryParams: {
          ...this.defaultDate,
          excludeIds: event?.join(',')
        }
      }
   )
  }

  excludeDispatcher(id) {
    this.filters.excludeIds = this.filters.excludeIds || []
    this.filters = { ...this.filters, excludeIds: [...this.filters.excludeIds, id] };
    this.selectUser(this.filters.excludeIds)
  }


  ngOnDestroy() {
    this.sidebarService.setShowNavigation(true)
    this.sidebarService.setShowSidebar(true)

    clearInterval(this.interval)
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}